/* eslint-disable */
import React from 'react';
import { Box, Menu, Text } from 'grommet';
import TextCell from 'granite-admin/core/components/Table/TextCell';

const COLUMNS = [
  {
    accessor: 'clientName',
    Header: 'Client Name',
  },
  {
    accessor: 'platform',
    Header: 'Platform',
    disableSortBy: true,
  },
  {
    accessor: 'ref_id',
    Header: 'Ref Id',
    disableSortBy: true,
  },
  {
    accessor: 'active_status',
    Header: 'Status',
    // disableSortBy: true,
    Cell: ({ cell: { value, row }, ...rest }) => (
      <TextCell
        {...rest}
        data={
          <Box direction="row" gap="small" align="center">
            <Box round="full" height="10px" width="10px" background={value === 'true' ? '#00d96f' : '#ff3d00'} />
            <Text>{value === 'true' ? 'Active' : 'Inactive'}</Text>
          </Box>
        }
      />
    ),
  },
  {
    accessor: 'order_sync_status',
    Header: 'Order Sync Status',
    disableSortBy: true,
    Cell: ({ cell: { value, row }, ...rest }) => (
      <TextCell
        {...rest}
        data={
          <Box direction="row" gap="small" align="center">
            {value !== 'NA' && (
              <Box round="full" height="10px" width="10px" background={value ? '#00d96f' : '#ff3d00'} />
            )}
            <Text>{value === 'NA' ? value : value ? 'Active' : 'Inactive'}</Text>
          </Box>
        }
      />
    ),
  },
];
// const SUBCOLUMNS = [
//   {
//     accessor: 'clientName',
//     Header: 'Client Name',
//     disableSortBy: true,
//   },
//   {
//     accessor: 'platform',
//     Header: 'Platform',
//     disableSortBy: true,
//   },
//   {
//     accessor: 'ref_id',
//     Header: 'Ref Id',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return (
//         <TextCell
//           {...rest}
//           title={undefined}
//           data={
//             <Box fill="horizontal" direction="row" justify="between" wrap style={{ marginRight: '10px' }}>
//               <Tooltip
//                 border={{ color: 'lightgray', size: 'xsmall' }}
//                 background="white"
//                 align="bottom"
//                 // width={{ max: '240px' }}
//                 // height={{ max: '120px' }}
//                 content={<Text>{value}</Text>}
//               >
//                 <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>
//               </Tooltip>
//             </Box>
//           }
//         />
//       );
//     },
//   },
//   {
//     accessor: 'active_status',
//     Header: 'Status',
//     disableSortBy: true,
//     Cell: ({ cell: { value, row }, updateCellData, ...rest }) => (
//       <TextCell
//         {...rest}
//         data={
//           <Menu
//             size="small"
//             label={
//               <Box direction="row" gap="small" align="center">
//                 <Box round="full" height="10px" width="10px" background={value ? '#00d96f' : '#ff3d00'} />
//                 <Text>{value ? 'Active' : 'Inactive'}</Text>
//               </Box>
//             }
//             dropProps={{ restrictFocus: false }}
//             items={[
//               {
//                 label: value ? 'Inactive' : 'Active',
//                 // onClick: () => updateCellData(row.original, 'is_active', !value),
//               },
//             ]}
//           />
//         }
//       />
//     ),
//   },
//   {
//     accessor: 'order_syn_status',
//     Header: 'Order Syn Status',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return <span>{value.toString()}</span>;
//     },
//   },
//   {
//     accessor: 'authentication_details',
//     Header: 'Authentication Details',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return (
//         <TextCell
//           {...rest}
//           title={undefined}
//           data={
//             <Box fill="horizontal" direction="row" justify="between" wrap style={{ marginRight: '10px' }}>
//               <Tooltip
//                 border={{ color: 'lightgray', size: 'xsmall' }}
//                 background="white"
//                 align="bottom"
//                 // width={{ max: '240px' }}
//                 // height={{ max: '120px' }}
//                 content={<Text>{value}</Text>}
//               >
//                 <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>
//               </Tooltip>
//             </Box>
//           }
//         />
//       );
//     },
//   },
//   {
//     accessor: 'marketplaces_and_claiming_policies',
//     Header: 'Marketplaces and Claiming policies',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>;
//     },
//   },
//   {
//     accessor: 'custom_fields',
//     Header: 'Custom Fields',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return (
//         <TextCell
//           {...rest}
//           title={undefined}
//           data={
//             <Box fill="horizontal" direction="row" justify="between" wrap style={{ marginRight: '10px' }}>
//               <Tooltip
//                 border={{ color: 'lightgray', size: 'xsmall' }}
//                 background="white"
//                 align="bottom"
//                 // width={{ max: '240px' }}
//                 // height={{ max: '120px' }}
//                 content={<Text>{value}</Text>}
//               >
//                 <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>
//               </Tooltip>
//             </Box>
//           }
//         />
//       );
//     },
//   },
//   {
//     accessor: 'ftp_folders',
//     Header: 'FTP Folders',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>;
//     },
//   },
//   {
//     accessor: 'api_error',
//     Header: 'Fetch Order API Error ',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>;
//     },
//   },
//   {
//     accessor: 'last_order_fetch_time',
//     Header: 'Last Order Fetch time ',
//     disableSortBy: true,
//     Cell: ({ row, value, ...rest }) => {
//       return <span {...row?.getToggleRowExpandedProps?.()}>{value}</span>;
//     },
//   },
// ];

export { COLUMNS };
