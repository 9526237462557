import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, CheckBox, Text } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import InfoText from 'common/components/infoText';

const Customisations = ({ setComp, comp, initialValues, handleUpdateData, id, data, errorToast }) => {
  const [showDiscountCodes, setShowDiscountCodes] = useState(initialValues?.activate_customization);
  const [showDiscountCodesField, setShowDiscountCodesField] = useState(false);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      const payload = {
        id,
        settings: {
          ...data?.settings?.dynamic,
          clientData: {
            ...data?.settings?.dynamic?.clientData,
            activate_customization: values?.activate_customization,
            // pro_discount_codes: values?.pro_discount_codes
            //   .trim()
            //   .split(',')
            //   .map(i => i.trim()),
          },
        },
        displayName: data?.displayName,
        storefrontIds:
          data?.settings?.dynamic?.quiversData?.marketplaces?.map(i => i?.marketplace_id)?.filter(n => n) || [],
      };
      await handleUpdateData(payload);
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Update Data');
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (data?.settings?.dynamic?.clientData?.show_discount_codes) setShowDiscountCodesField(true);
  }, [setShowDiscountCodesField, data]);

  return (
    <Box width={'70%'} pad={{ top: 'large' }} border={{ color: 'lightgray', size: 'xsmall', side: 'top' }}>
      <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues}>
        {({ values, handleSubmit, handleChange, isSubmitting, setFieldValue, handleBlur }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box pad={{ bottom: 'large' }}>
              <Text size="xlarge" weight="bold">
                Customization allows you to tailor the integration to your specific needs and requirements. This has
                been enabled because you have requested for the customization. You can disable it anytime.
              </Text>
            </Box>
            <Box justify="center" width="100%" direction="row" gap="medium">
              <Text size="large">Activate Customization</Text>
              <CheckBox
                name="activate_customization"
                toggle
                reverse
                checked={values.activate_customization}
                onChange={e => {
                  setFieldValue('activate_customization', e.target.checked);
                  setShowDiscountCodes(e.target.checked);
                  if (!e.target.checked) {
                    setFieldValue('pro_discount_codes', '');
                  }
                }}
              />
            </Box>
            {showDiscountCodesField && (
              <Box margin={{ vertical: 'medium' }} gap="small">
                <Box flex direction="row" gap="medium" align="center">
                  <Text size="xlarge">Discount Codes</Text>
                  {/* <InfoText text="First activate customization then add comma separated codes here and save. These discount codes will prevent orders from syncing to Quivers." /> */}
                </Box>
                <TextInput
                  name="pro_discount_codes"
                  value={values.pro_discount_codes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!showDiscountCodes}
                />
              </Box>
            )}
            <Box justify="end" gap="xlarge" direction="row" margin={{ top: 'xxlarge' }}>
              <Box align="end">
                <Button label={'Back'} primary onClick={() => setComp(comp - 1)} disabled={isSubmitting} />
              </Box>
              <Box align="end">
                {/* {_.isEqual(initialValues, values) ? (
                  <Button label={'Next'} primary onClick={() => setComp(comp + 1)} disabled={isSubmitting} />
                ) : ( */}
                <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} primary disabled={isSubmitting} />
                {/* )} */}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

Customisations.propTypes = {
  setComp: PropTypes.func,
  errorToast: PropTypes.func,
  comp: PropTypes.number,
  data: PropTypes.object,
  initialValues: PropTypes.object,
  handleUpdateData: PropTypes.func,
  id: PropTypes.string,
};

export default Customisations;
