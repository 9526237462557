import React from 'react';
import { Text } from 'grommet';
import PropTypes from 'prop-types';

import Tooltip from 'granite-admin/core/components/Tooltip';
import InfoIcon from 'granite-admin/common/assets/InfoIcon';

const InfoText = ({ text }) => {
  return (
    <Tooltip
      border={{ color: 'lightgray', size: 'xsmall' }}
      background="gray"
      width="medium"
      content={<Text size='small' color="white">{text}</Text>}
    >
      <InfoIcon />
    </Tooltip>
  );
};

InfoText.propTypes = {
  text: PropTypes.string,
};

export default InfoText;
