import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Text, Button, Grid, TextInput } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Loader from 'granite-admin/core/components/Loader';
import InfoText from 'common/components/infoText';

import * as Yup from 'yup';

import _ from 'lodash';
import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';
import bigCommerce from 'common/gateways/common-api.js';
import warehousesApi from 'bigCommerce/gateways/bigCommerce-api.js';

const StoreConfiguration = ({
  setComp,
  comp,
  InitialValuesStore,
  business,
  data,
  successToast,
  id,
  errorToast,
  setDisabledAll,
  setData,
}) => {
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [datas, setDatas] = useState(InitialValuesStore?.[0]);
  const [loader, setLoader] = useState(true);
  const [formLoader, setFormLoader] = useState(true);

  const market = useMemo(() => {
    return data?.settings?.dynamic?.quiversData?.marketplaces || [];
  }, [data?.settings?.dynamic?.quiversData?.marketplaces]);

  const validationSchema = Yup.object().shape({
    marketplace_id: Yup.string().required('Please select a Quivers Storefront for your Store.'),
    orderPrefix: Yup.string().required('Order prefix is required'),
  });

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      setDisabledAll(true);
      const updateOrAddMarketplace = (array, id, newProperties) => {
        const updatedArray = array.map(obj => {
          if (obj.store_id === id) {
            return { ...obj, ...newProperties };
          }
          return obj;
        });
        const existingObjectIndex = updatedArray.findIndex(obj => obj.store_id === id);
        if (existingObjectIndex === -1) {
          updatedArray.push({ id, ...newProperties });
        }
        return updatedArray;
      };

      const updatedArray = updateOrAddMarketplace(market, values.id, {
        marketplace_id: values?.marketplace_id,
        marketplace_name: values?.marketplace_name,
        store_id: values?.id,
        sync_retailer_inventory: true,
        warehouses: values?.warehouses,
      });

      let StoreNameMapping = data?.settings?.dynamic?.quiversData?.store_name_mapping || {};
      const StoreNameMappingArray = Object.values(StoreNameMapping);
      if (StoreNameMappingArray.includes(values?.orderPrefix)) {
        errorToast('Order prefix is already present for the Storefront.');
        return;
      }
      StoreNameMapping[values?.marketplace_id] = values?.orderPrefix;

      const payload = {
        id,
        settings: {
          ...data?.settings?.dynamic,
          quiversData: {
            ...data?.settings?.dynamic?.quiversData,
            marketplaces: updatedArray,
            store_name_mapping: StoreNameMapping,
          },
        },
        displayName: data?.displayName,
      };
      await bigCommerce.updateData({ business, payload });
      const res = await bigCommerce.getData({ id, business });
      setData(res);
      setDisabledAll(false);
      setDatas(values);
      successToast('Data updated sucessfully');
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Update Data');
    }
    setSubmitting(false);
  };

  const getMarketplace = useCallback(async () => {
    try {
      const data = await bigCommerce.getMarketplace(business);
      const warehousesData = await warehousesApi.getWarehouses(business);
      setLoader(false);
      setWarehouseData(warehousesData);
      if (market?.length > 0) {
        const newData = data?.filter(obj => {
          return !market?.some(obj2 => obj2?.marketplace_id === obj.Id);
        });
        const derOption = data.find(obj => obj.Id === datas?.marketplace_id);
        const result = derOption ? newData.concat(derOption) : newData;
        setMarketplaceOptions(result);
      } else setMarketplaceOptions(data);

      setFormLoader(false);
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Load Options');
    }
  }, [business, datas, errorToast, market]);

  useEffect(() => {
    getMarketplace();
  }, [getMarketplace]);

  const initialValues = {
    id: datas?.id || '',
    marketplace_name: datas?.marketplace_name || '',
    marketplace_id: datas?.marketplace_id || '',
    store_name: datas?.store_name || '',
    orderPrefix: datas?.orderPrefix || '',
    warehouses: datas?.warehouses || [],
  };

  return (
    <Box width={'70%'} pad={{ top: 'large' }} border={{ color: 'lightgray', size: 'xsmall', side: 'top' }}>
      {loader ? (
        <Box fill justify="center" align="center">
          <Loader />
        </Box>
      ) : (
        <>
          <Box pad={{ bottom: 'large' }}>
            <Text size="xlarge" margin={{ bottom: 'medium' }} weight="bold">
              Select the Store for which you want to configure Quivers Pay settings
            </Text>
            <Box width="38%" margin={{ right: 'large' }}>
              <Box direction="row" gap="small">
                <Text margin={{ bottom: 'small' }} weight={'bold'}>
                  Select a Store
                </Text>
                <InfoText text="Select the Store for which you want to configure Quivers Pay settings." />
              </Box>
              <FormField name="id" noBorder width={'100%'}>
                <Box border="1px solid black">
                  <Select
                    options={InitialValuesStore || []}
                    name="id"
                    onChange={e => {
                      setDatas(e?.value);
                    }}
                    value={datas?.id}
                    valueKey={{ key: 'id', reduce: false }}
                    labelKey="label"
                  />
                </Box>
              </FormField>
            </Box>
            <Text size="xlarge" margin={{ top: 'medium' }} weight="bold">
              Select Storefront and enter Order Prefix for the corresponding Store.
            </Text>
          </Box>
          <Box flex gap="medium" direction="row" margin={{ top: 'small' }}>
            <Box style={{ flex: 'auto' }}>
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
              >
                {({ values, handleSubmit, setFieldValue, handleBlur, handleChange, touched, errors, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    {formLoader ? (
                      <Box fill justify="center" align="center">
                        <Loader />
                      </Box>
                    ) : (
                      <>
                        {/* <Box width={'100%'} flex gap="medium" direction="row"> */}
                        <Box>
                          {/* <Grid
                          columns={[
                            ['small', 'medium', 'medium'],
                            ['small', 'medium', 'medium'],
                          ]}
                          gap={{ row: 'medium', column: 'small' }}
                        > */}
                          <Box
                            justify="between"
                            direction="row"
                            margin={{ bottom: 'xlarge' }}
                            border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
                          >
                            <Box
                              width={'50%'}
                              margin={{ bottom: 'medium' }}
                              border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                            >
                              <Box direction="row" gap="small">
                                <Text margin={{ bottom: 'small' }} weight={'bold'}>
                                  Store Name
                                </Text>
                                <InfoText text="This is the BigCommerce store name from which the orders will be synced." />
                              </Box>
                              <Box pad={'small'} width={'70%'}>
                                <Text truncate="tip">{values?.store_name}</Text>
                              </Box>
                            </Box>
                            <Box width={'40%'}>
                              <Box direction="row" gap="small">
                                <Text weight="bold">Quivers Storefront</Text>
                                <InfoText text="Select the Quivers storefront that corresponds to the BigCommerce store." />
                              </Box>
                              <FormField
                                name="marketplace_id"
                                noBorder
                                error={touched.marketplace_id && errors.marketplace_id}
                              >
                                <Box border="1px solid black" width={'60%'}>
                                  <Select
                                    placeholder="Select Storefront"
                                    options={marketplaceOptions || []}
                                    name="marketPlaces"
                                    onChange={e => {
                                      if (e?.value) {
                                        setFieldValue('warehouses', e?.value?.Warehouses);
                                        setFieldValue('marketplace_id', e?.value?.Id);
                                        setFieldValue('marketplace_name', e?.value?.Name);
                                      } else {
                                        setFieldValue('warehouses', '');
                                        setFieldValue('marketplace_id', '');
                                        setFieldValue('marketplace_name', '');
                                      }
                                    }}
                                    value={values?.marketplace_id}
                                    valueKey={{ key: 'Id', reduce: false }}
                                    labelKey="Name"
                                    onBlur={handleBlur}
                                  />
                                </Box>
                              </FormField>
                            </Box>
                          </Box>
                          <Box
                            justify="between"
                            direction="row"
                            margin={{ bottom: 'large' }}
                            // border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
                          >
                            <Box
                              width={'50%'}
                              margin={{ bottom: 'medium' }}
                              border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                            >
                              <Box direction="row" gap="small">
                                <Text weight="bold" margin={{ bottom: 'small' }}>
                                  Warehouses
                                </Text>
                                <InfoText text="These are the warehouses linked to the selected storefront. " />
                              </Box>

                              <Grid
                                columns={[
                                  ['35%', '35%'],
                                  ['35%', '35%'],
                                ]}
                                gap={{ row: 'small', column: 'small' }}
                              >
                                {/* <Box direction="row"> */}
                                {/* {newArray.map(e => (
                              <Box border pad={'small'} margin={{ horizontal: 'medium' }} width={'100%'}>
                                <Text truncate="tip">{e.e}</Text>
                              </Box>
                            ))} */}
                                {/* </Box> */}

                                {warehouseData
                                  ?.filter(obj => values?.warehouses?.includes(obj?.Id))
                                  .map(obj => obj?.Name)
                                  .map(e => (
                                    <Box
                                      background={'silver'}
                                      border
                                      pad={'small'}
                                      margin={{ horizontal: 'medium' }}
                                      width="100%"
                                    >
                                      <Text truncate="tip">{e}</Text>
                                    </Box>
                                  ))}
                              </Grid>
                            </Box>
                            <Box width="40%">
                              <Box direction="row" gap="small">
                                <Text weight="bold">Order prefix</Text>
                                <InfoText text="Enter the order prefix that will be visible alongside the BigCommerce order ID on Quivers. Each store has a unique order prefix." />
                              </Box>
                              <FormField
                                name={'orderPrefix'}
                                noBorder
                                error={touched.orderPrefix && errors.orderPrefix}
                              >
                                <Box border="1px solid black" width="60%">
                                  <TextInput
                                    placeholder="order Prefix"
                                    pad="small"
                                    name="orderPrefix"
                                    value={values?.orderPrefix}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </FormField>
                            </Box>
                          </Box>
                          <Box></Box>
                          {/* </Grid> */}
                          {/* <Box justify="right" alignSelf="end" margin={{ left: 'small' }}>
                            <Button
                              type="submit"
                              label={isSubmitting ? 'Saving...' : 'Save'}
                              primary
                              disabled={_.isEqual(initialValues, values)}
                            />
                          </Box> */}
                        </Box>
                        {/* </Box> */}
                        <Box justify="end" gap="xlarge" direction="row" margin={{ top: 'large' }}>
                          <Box align="end">
                            <Button label={'Back'} primary onClick={() => setComp(comp - 1)} />
                          </Box>
                          <Box align="end">
                            {_.isEqual(initialValues, values) ? (
                              <Button
                                label={'Next'}
                                primary
                                onClick={() => setComp(comp + 1)}
                                disabled={!values?.marketplace_id}
                              />
                            ) : (
                              <Button
                                type="submit"
                                label={isSubmitting ? 'Saving...' : 'Save'}
                                primary
                                disabled={_.isEqual(initialValues, values)}
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
StoreConfiguration.propTypes = {
  setComp: PropTypes.func,
  comp: PropTypes.number,
  initialValues: PropTypes.object,
  handleUpdateData: PropTypes.func,
  business: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  errorToast: PropTypes.func,
  setDisabledAll: PropTypes.func,
  setData: PropTypes.func,
  successToast: PropTypes.any,
  InitialValuesStore: PropTypes.any,
};

export default StoreConfiguration;
