// Lib Imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from 'grommet';

import EventEmitter from 'granite-admin/utils/event-emitter';
import Loader from 'granite-admin/core/components/Loader';
import { deleteCookie } from 'granite-admin/utils/storage';
import { useToast } from 'granite-admin/core/components/Toast';
import useQuery from 'granite-admin/utils/useQuery';

import Connector from 'common/components/connectors';
import MarketPlaces from 'pages/bigCommerce/components/Marketplaces';
import FlowSettings from 'pages/bigCommerce/components/FlowSettings';
import Customisations from 'pages/bigCommerce/components/Customization';
import QuiversPay from 'pages/bigCommerce/components/QuiversPay';
import Bopis from 'pages/bigCommerce/components/bopis';
import StoreConfigurations from 'pages/bigCommerce/components/StoreConfigurations';
import { updateData, getData } from 'common/controllers/common.js';

const BigCommerce = () => {
  const { successToast, errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const [comp, setComp] = useState(1);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [disabledAll, setDisabledAll] = useState(false);
  const [showCustomisation, setShowCustomisation] = useState(false);
  const { query } = useQuery();
  const id = query.id;
  const business = query.business;

  const fetchData = useCallback(async () => {
    await getData(eventEmitter, query.id, query.business);
  }, [eventEmitter, query]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'GET_DATA_SUCCESS':
          if (event?.data?.settings?.dynamic?.clientData?.show_customisation) {
            setShowCustomisation(true);
          }
          setData(event?.data);
          setLoader(false);
          break;
        case 'GET_DATA_FAILURE':
          errorToast('Something went wrong!!!');
          setLoader(false);
          break;
        case 'UPDATE_DATA_SUCCESS':
          successToast('Data updated successfully');
          if ((showCustomisation && comp < 5) || comp < 4) setComp(comp + 1);
          setDisabledAll(false);
          fetchData();
          break;
        case 'UPDATE_DATA_FAILURE':
          errorToast('Something went wrong!!!');
          setDisabledAll(false);
          break;
        default:
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [eventEmitter, comp, errorToast, successToast, fetchData, showCustomisation]);

  const handleUpdateData = useCallback(
    async payload => {
      setDisabledAll(true);
      await updateData(eventEmitter, business, payload, data);
    },
    [business, eventEmitter, data],
  );

  // const initialvalueQuiversPay = useMemo(() => {
  //   if (data && data?.settings?.dynamic?.clientData.store_data) {
  //     const { store_data } = data?.settings?.dynamic?.clientData;
  //     const marketPlaceId = Object.keys(store_data);
  //     return marketPlaceId[0];
  //   }
  // }, [data]);

  const initialvalueStoreData = useMemo(() => {
    if (data && data?.settings?.dynamic?.clientData?.store_data) {
      const { store_data } = data?.settings?.dynamic?.clientData;
      const marketPlaceId = Object.keys(store_data);
      const marketPlaceIdall = marketPlaceId.map(storeId => {
        const foundItem = data.settings?.dynamic?.quiversData?.marketplaces?.find(item => item.store_id === storeId);
        if (foundItem) {
          return {
            id: storeId,
            marketplace_name: foundItem.marketplace_name,
            marketplace_id: foundItem?.marketplace_id,
            warehouses: foundItem?.warehouses,
          };
        }
      });
      const res = Object.keys(store_data).reduce((acc, curr) => {
        return [
          ...acc,
          {
            ...store_data[curr],
            id: curr,
          },
        ];
      }, []);
      const combinedOrder = marketPlaceIdall.map(item => ({
        ...item,
        orderPrefix: data?.settings?.dynamic?.quiversData?.store_name_mapping[item?.marketplace_id],
      }));
      const combinedData = res.map(dataItem => {
        const matchingMarketplaceObj = combinedOrder.find(obj => obj?.id === dataItem?.id);
        if (matchingMarketplaceObj) {
          return { ...dataItem, ...matchingMarketplaceObj };
        }
        return dataItem;
      });

      const finalStoreData = combinedData.map(item => {
        const label = item.store_name + ' (' + item.id + ')';
        return {
          ...item,
          label: label,
        };
      });

      return finalStoreData;
    }
    return null;
  }, [data]);

  useEffect(() => {
    fetchData();
    deleteCookie('id');
    deleteCookie('business');
  }, [fetchData]);

  return (
    <Box background="white" height="100%" align="center">
      {loader ? (
        <Box fill justify="center" align="center">
          <Loader />
        </Box>
      ) : (
        <Box flex={false} gap="medium">
          <Box margin={{ top: '30px' }}>
            <Connector
              activeId={comp - 1}
              steps={['Stores', 'Stores Configuration', 'Quivers Pay', 'BOPIS', 'Flow Settings', 'Customization']}
              setComp={setComp}
              disabledAllIndx={disabledAll}
            />
          </Box>
          <Box direction="row" justify="center" align="center" width="100%" pad={{ top: 'large' }}>
            {comp === 1 ? (
              <MarketPlaces
                setComp={setComp}
                comp={comp}
                id={id}
                business={business}
                InitialValuesStore={initialvalueStoreData}
              />
            ) : comp === 2 ? (
              <StoreConfigurations
                setComp={setComp}
                comp={comp}
                eventEmitter={eventEmitter}
                data={data}
                id={id}
                successToast={successToast}
                InitialValuesStore={initialvalueStoreData}
                handleUpdateData={handleUpdateData}
                business={business}
                errorToast={errorToast}
                setDisabledAll={setDisabledAll}
                setData={setData}
              />
            ) : comp === 3 ? (
              <QuiversPay
                id={id}
                setComp={setComp}
                comp={comp}
                eventEmitter={eventEmitter}
                data={data}
                storeData={initialvalueStoreData}
                successToast={successToast}
                handleUpdateData={handleUpdateData}
                errorToast={errorToast}
              />
            ) : comp === 4 ? (
              <Bopis
                id={id}
                setComp={setComp}
                comp={comp}
                data={data}
                storeData={initialvalueStoreData}
                errorToast={errorToast}
                business={business}
                successToast={successToast}
                initialValues={{
                  debug_mode: data?.settings?.dynamic?.bopis?.debug_mode || false,
                }}
                handleUpdateData={handleUpdateData}
              />
            ) : comp === 5 ? (
              <FlowSettings
                setComp={setComp}
                comp={comp}
                eventEmitter={eventEmitter}
                data={data}
                initialValues={{
                  sync_quivers_shipments: data?.settings?.dynamic?.configuration?.sync_quivers_shipments || {
                    flag: false,
                  },
                  sync_quivers_cancellations: data?.settings?.dynamic?.configuration?.sync_quivers_cancellations || {
                    flag: false,
                  },
                  sync_client_shipments: data?.settings?.dynamic?.configuration?.sync_client_shipments || {
                    flag: false,
                    interval: '5',
                  },
                  sync_quivers_returns: data?.settings?.dynamic?.configuration?.sync_quivers_returns || {
                    flag: false,
                  },
                  sync_client_refunds: data?.settings?.dynamic?.configuration?.sync_client_refunds || {
                    flag: false,
                    interval: '5',
                  },
                  sync_client_cancellations: data?.settings?.dynamic?.configuration?.sync_client_cancellations || {
                    flag: false,
                    interval: '5',
                  },
                  sync_client_orders: data?.settings?.dynamic?.configuration?.sync_client_orders || {
                    flag: true,
                    interval: '5',
                  },
                  sync_products: data?.settings?.dynamic?.configuration?.sync_products || {
                    flag: true,
                    interval: '30',
                  },
                  claim_orders: data?.settings?.dynamic?.configuration?.claim_orders || {
                    flag: false,
                    interval: '5',
                  },
                  sync_quivers_retailer_shipments: data?.settings?.dynamic?.configuration
                    ?.sync_quivers_retailer_shipments || {
                    flag: false,
                  },
                  sync_quivers_retailer_returns: data?.settings?.dynamic?.configuration
                    ?.sync_quivers_retailer_returns || {
                    flag: false,
                  },

                  sync_quivers_inventory: data?.settings?.dynamic?.configuration?.sync_quivers_inventory || {
                    flag: true,
                    interval: '30',
                  },
                }}
                id={id}
                business={business}
                handleUpdateData={handleUpdateData}
                fetchData={fetchData}
                errorToast={errorToast}
                setDisabledAll={setDisabledAll}
              />
            ) : comp === 6 ? (
              <Customisations
                id={id}
                setComp={setComp}
                comp={comp}
                eventEmitter={eventEmitter}
                data={data}
                initialValues={{
                  activate_customization: data?.settings?.dynamic?.clientData?.activate_customization || false,
                  pro_discount_codes: data?.settings?.dynamic?.clientData?.pro_discount_codes?.toString() || [],
                }}
                handleUpdateData={handleUpdateData}
                errorToast={errorToast}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BigCommerce;
