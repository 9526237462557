import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

import theme from 'granite-admin/core/theme';
import { loadGrandEmitter } from 'granite-admin/utils/grandEmitter';
import config from 'granite-admin/core/config';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from 'granite-admin/core/components/PublicOnlyRoute';
import PublicRoute from 'granite-admin/core/components/PublicRoute';
import NotFoundPage from 'granite-admin/core/components/NotFoundPage';
import { ToastProvider } from 'granite-admin/core/components/Toast/ToastProvider';
import { ConfigProvider } from 'granite-admin/core/components/ConfigProvider';

import configureStore from 'granite-admin/utils/ducks/configure-store';
import { loadAuthToken, loadOrganisation, loadMyOrganisations } from 'granite-admin/utils/auth-singleton';
import appRoutes from 'routes';
import projectTheme from 'project_theme';
import g12Theme from './g12_theme';
import g12Config from 'g12_config';

let routes = appRoutes;

// Init Auth Singleton at load
loadAuthToken();
loadOrganisation();
loadMyOrganisations();
loadGrandEmitter();

// Create our Redux store.
const store = configureStore();
const customTheme = deepMerge(theme, g12Theme);
const customConfig = deepMerge(config, g12Config);

const App = () => {
  return (
    <ReduxProvider store={store}>
      <Grommet theme={customTheme} full>
        <Router>
          <ConfigProvider config={customConfig}>
            <ToastProvider>
              <Routes>
                {routes.map(route => {
                  if (route.private) {
                    return (
                      <Route
                        path={route.path}
                        key={route.path}
                        element={<PrivateRoute key={route.path} addTabBtn={false} {...route} />}
                      />
                    );
                  } else if (route.publicOnly)
                    return (
                      <Route
                        path={route.path}
                        key={route.path}
                        element={<PublicOnlyRoute key={route.path} {...route} />}
                      />
                    );
                  else
                    return (
                      <Route path={route.path} key={route.path} element={<PublicRoute key={route.path} {...route} />} />
                    );
                })}
                <Route path="*" render={() => <NotFoundPage />} />
              </Routes>
            </ToastProvider>
          </ConfigProvider>
        </Router>
      </Grommet>
    </ReduxProvider>
  );
};

export default App;
