import React, { useState, useCallback } from 'react';
import { Box, Button, Text, CheckBox } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';

import ConfirmPopup from 'granite-admin/core/components/Confirm';
import Tooltip from 'granite-admin/core/components/Tooltip';
import InfoIcon from 'granite-admin/common/assets/InfoIcon';
import BigCommerceConfigApi from 'bigCommerce/gateways/bigCommerce-api';

const StyledLayer = styled(ConfirmPopup)`
background-color: rgba(0,0,0,0.5);

> div {background-color:white;

  border: 1px black solid;}
@media (max-width: 768px) {
  > div {         
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;}
  
`;

const bopisRedirectUrl = process.env.REACT_APP_BOPIS_ACTIVATE;
const redirectEnv = process.env.REACT_APP_REDIRECT_ENV;

const Bopis = ({ setComp, comp, data, errorToast, business, initialValues, handleUpdateData, id, storeData }) => {
  const [layer, setLayer] = useState(false);
  const [store, setStore] = useState(null);
  const [next, setNext] = useState(false);
  const [disableBopisBtn, setDisableBopisBtn] = useState(true);
  const [activateBopis, setActivateBopis] = useState(null);

  const saveBopisTimer = useCallback(
    async (storeID, install) => {
      try {
        let bopisActive = data?.timers?.bopis_active || {};
        bopisActive[storeID] = install;
        const Payload = {
          id,
          timers: {
            bopis_activate: bopisActive,
          },
        };
        await BigCommerceConfigApi.updateBopisTimer(business, Payload);
      } catch (e) {
        errorToast(e.errors?.title || 'Failed to Update Settings');
      }
    },
    [business, data, errorToast, id],
  );

  const saveSettingsApi = useCallback(
    async value => {
      try {
        const payloadBopis = {
          debug_mode: data?.settings?.dynamic?.bopis?.debug_mode || 0,
          storehash: value.id,
          store_name: value.store_name,
          sandbox_mode: redirectEnv,
          businessRefId: business,
          marketplaces: [{ marketplace_id: value?.marketplace_id }],
          clientdata: {
            app_id: id,
            base_url: value.store_url,
          },
        };
        const res = await BigCommerceConfigApi.saveBopisSettings(payloadBopis);
        saveBopisTimer(value.id, res?.install);
        if (res?.install) {
          setActivateBopis(true);
        } else {
          setActivateBopis(false);
        }
      } catch (e) {
        errorToast(e.errors?.title || 'Failed to save settings');
      }
      setDisableBopisBtn(false);
    },
    [data, business, saveBopisTimer, errorToast],
  );

  // const deactivateBopis = useCallback(async () => {
  //   try {
  //     const params = {
  //       base_url: store.store_url,
  //       store_hash: store.id,
  //     };
  //     const res = await BigCommerceConfigApi.deactivateBopis({ params });
  //     if (res?.sucess) {
  //       setActivateBopis(false);
  //     }
  //     setDisableBopisBtn(false);
  //     successToast(res?.message);
  //     // setLoader(true);
  //     saveSettingsApi(store);
  //   } catch (e) {
  //     errorToast(e.errors?.title || 'Failed to deactivate Bopis');
  //   }
  //   setDisableBopisBtn(false);
  // }, [successToast, saveSettingsApi, store, errorToast]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      const payload = {
        id,
        settings: {
          ...data?.settings?.dynamic,
          bopis: {
            ...data?.settings?.dynamic?.bopis,
            debug_mode: values?.debug_mode,
          },
        },

        displayName: data?.displayName,
      };
      await handleUpdateData(payload);
      setNext(true);
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Update Settings');
    }
    setSubmitting(false);
  };

  return (
    <Box width={'70%'} pad={{ top: 'large' }} border={{ color: 'lightgray', size: 'xsmall', side: 'top' }}>
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, handleSubmit, handleChange, isSubmitting, setFieldValue, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box gap="medium">
              {/* {!activateBopis && ( */}
              <Box pad={{ bottom: 'medium' }}>
                <Text size="xlarge" weight={'bold'}>
                  Activate Quivers In-Store Pickup to install the Quivers BOPIS on your BigCommerce store.
                </Text>
              </Box>
              {/* )} */}
              <Box
                direction="row"
                justify="between"
                // margin={{ bottom: 'large' }}
                border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
              >
                <Box
                  width={'50%'}
                  margin={{ bottom: 'medium' }}
                  border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                >
                  <Text margin={{ bottom: 'small' }} weight={'bold'}>
                    Select a Store
                  </Text>
                  <FormField name="id" noBorder width={'60%'}>
                    <Box border="1px solid black">
                      <Select
                        placeholder="Select a Store"
                        options={storeData || []}
                        name="id"
                        onChange={({ value }) => {
                          if (value) {
                            setStore(value);
                            saveSettingsApi(value);
                          } else setStore({});
                        }}
                        value={store?.id}
                        valueKey={{ key: 'id', reduce: false }}
                        labelKey="label"
                      />
                    </Box>
                  </FormField>
                </Box>

                <Box width={'40%'} gap="small">
                  <Box justify="center">
                    <Text weight="bold">Quivers In-Store Pickup</Text>
                  </Box>
                  {store?.id && (
                    <>
                      {/* {!activateBopis ? ( */}
                      <CheckBox
                        // name="Activate"
                        label={
                          <Text size="large" weight="bold">
                            {!activateBopis ? 'Activate' : 'Activated'}
                          </Text>
                        }
                        checked={activateBopis}
                        toggle
                        disabled={disableBopisBtn || activateBopis}
                        onChange={e => {
                          setDisableBopisBtn(true);
                          setLayer(true);
                        }}
                      />

                      {activateBopis && (
                        <Text gap="small" size="small">
                          NOTE: To deactivate visit Bigcommerce Admin Panel
                        </Text>
                      )}
                      {/* ) : ( */}
                      {/* <Button
                           plain
                           style={{ padding: '4px 23px' }}
                           label={'Activate'}
                           onClick={() => {
                             setDisableBopisBtn(true);
                             setLayer(true);
                           }}
                           primary
                           disabled={disableBopisBtn}
                         />
                         <> */}
                      {/* <Popconfirm
                          align="right"
                          onConfirm={() => {
                            setDisableBopisBtn(true);
                            deactivateBopis();
                          }}
                        > */}
                      {/* <Button
                            color={'#25a925'}
                            plain
                            style={{ padding: '4px 16.5px' }}
                            label={'Activated'}
                            primary
                          /> */}
                      {/*  */}
                      {/* </Popconfirm> */}
                      {/* </> */}
                      {/* // )} */}
                    </>
                  )}
                </Box>
              </Box>
              {activateBopis && (
                <Box gap="medium" width="50%" border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}>
                  <Box direction="row" align="center" gap="small">
                    <Text weight="bold">Debug Mode</Text>
                    <Tooltip
                      border={{ color: 'lightgray', size: 'xsmall' }}
                      background="black"
                      content={<Text>Enable debug mode and click on save button to generate logs on Quivers end.</Text>}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                  <CheckBox
                    name="debug_mode"
                    toggle
                    reverse
                    checked={values.debug_mode}
                    onChange={e => {
                      setFieldValue('debug_mode', e?.target?.checked);
                    }}
                  />
                </Box>
              )}
              {/* <Box margin={{ top: 'large' }} alignSelf="end">
                <Button
                  type="submit"
                  label={isSubmitting ? 'Saving...' : 'Save'}
                  primary
                  disabled={isSubmitting || _.isEqual(initialValues, values) || !activateBopis}
                />
              </Box> */}
            </Box>
            <Box justify="end" gap="xlarge" direction="row" margin={{ vertical: 'large' }}>
              <Box align="end">
                <Button label={'Back'} primary onClick={() => setComp(comp - 1)} />
              </Box>
              <Box align="end">
                {_.isEqual(initialValues, values) || next ? (
                  <Button label={'Next'} primary onClick={() => setComp(comp + 1)} />
                ) : (
                  <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} primary disabled={!activateBopis} />
                )}
              </Box>
            </Box>

            {layer && (
              <StyledLayer
                width="25rem"
                isVisible
                heading="ACTIVATE BOPIS"
                onOK={() => {
                  const child = window.open(bopisRedirectUrl, '', 'width=800,height=600');
                  const checkChild = () => {
                    if (child?.closed) {
                      clearInterval(timer);
                      setDisableBopisBtn(false);

                      saveSettingsApi(store);
                    }
                  };
                  var timer = setInterval(checkChild, 500);
                  setLayer(false);
                }}
                text="Are you sure you want to activate BOPIS?"
                togglePopup={() => {
                  setDisableBopisBtn(false);
                  setLayer(false);
                }}
                labelCancel="Cancel"
                labelOK="Confirm"
              />
            )}
          </form>
        )}
      </Formik>
      {/* )} */}
    </Box>
  );
};

Bopis.propTypes = {
  setComp: PropTypes.func,
  errorToast: PropTypes.func,
  successToast: PropTypes.func,
  comp: PropTypes.number,
  data: PropTypes.object,
  initialValues: PropTypes.object,
  business: PropTypes.string,
  id: PropTypes.string,
  handleUpdateData: PropTypes.func,
  storeData: PropTypes.array,
};

export default Bopis;
