// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

/* eslint-disable */
const URL = process.env.REACT_APP_SFDC_BASE_URL;
const quiversUrl = process.env.REACT_APP_QUIVERS_BASE_URL;
const authToken = process.env.REACT_APP_SFDC_AUTH_TOKEN;

async function getAuthObj(params) {
  try {
    const { data } = await GraniteAPIClient(quiversUrl, { Authorization: getAuthToken() }).get(
      `GetBusinessPluginById`,
      { params: params },
    );
    return data.result;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getURL(params) {
  try {
    const { data } = await GraniteAPIClient(URL, { Authorization: `Bearer ${authToken}` }).get(`initialize`, {
      params: params,
    });
    return data?.url || '';
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function register(business, payload) {
  try {
    const { data } = await GraniteAPIClient(URL, { Authorization: `Bearer ${authToken}` }).post(
      `oauth?business=${business}`,
      payload,
    );
    return data.result;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getDashboardData(params) {
  try {
    const { data } = await GraniteAPIClient(URL, { Authorization: `Bearer ${authToken}` }).get(`dashboard`, {
      params: params,
    });
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function updateConfig(business, payload) {
  try {
    const { data } = await GraniteAPIClient(quiversUrl, { Authorization: getAuthToken() }).post(
      `Update?business=${business}`,
      payload,
    );
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

export default {
  getAuthObj,
  getURL,
  register,
  getDashboardData,
  updateConfig,
};
