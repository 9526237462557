const config = {
  sidebarSettings: {
    showReferGranite: false,
  },
  toast: {
    duration: 2000,
    closeOnClick: false,
    showCloseBtn: true,
    maxCount: 5,
  },
  listOpenNewTab: false,
  showCreateOrganisation: false,
  websocketMappings: {
    notifications: {
      //socket action name
      showToast: false,
      autoRedirect: false,
      redirectUrl: '',
      volatileStorage: false, //to store onmessage's data in browser's storage if data is volatile
    },
    widgetDashboard: {
      showToast: true,
      toastMsg: 'Widget is refreshed with updated data',
      autoRedirect: true,
      redirectUrl: '/my-boards/:id/:name',
      volatileStorage: true,
    },
  },
};

export default config;
