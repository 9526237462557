import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CheckBox, Text, TextInput } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _ from 'lodash';

import QuiversPayApi from 'bigCommerce/gateways/bigCommerce-api';
import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';
import InfoText from 'common/components/infoText';

const QuiversPay = ({ setComp, comp, errorToast, successToast, storeData, data }) => {
  // const validationSchema = Yup.object().shape({
  //   stripe: Yup.number(),
  //   api_client_id: Yup.string().when('stripe', {
  //     is: stripe => stripe === 1,
  //     then: Yup.string().required('Client Id is required'),
  //   }),
  //   api_client_secret: Yup.string().when('stripe', {
  //     is: stripe => stripe === 1,
  //     then: Yup.string().required('Client secret is required'),
  //   }),
  //   api_access_token: Yup.string().when('stripe', {
  //     is: stripe => stripe === 1,
  //     then: Yup.string().required('Access token is required'),
  //   }),
  // });

  const [quiversValue, setQuiversValue] = useState({});
  const [store, setStore] = useState({});
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      const payload = {
        ...values,
        store_hash: store.id,
        sandbox_mode: 0,
        paypal: 0,
        paymentkeys: {
          paypalClientId: data?.settings?.static?.paypalClientId || '',
          paypalClientSecret: data?.settings?.static?.paypalClientSecret || '',
          stripePublicKey: data?.settings?.static?.stripePublicKey || '',
        },
        marketplaces:  data?.settings?.dynamic?.quiversData?.marketplaces.find(obj => obj.id===store.id) || {},
      };
      await QuiversPayApi.postQuiversPay(payload);
      setQuiversValue(values);
      successToast('Data updated successfully');
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Update Data');
    }
    setSubmitting(false);
  };

  const fetchQuivers = useCallback(
    async storeId => {
      try {
        const QuiversData = await QuiversPayApi.getQuiversPay(storeId);
        setQuiversValue(QuiversData.data);
      } catch (e) {
        errorToast(e.errors?.title || 'Failed to fetch record');
      }
    },
    [errorToast],
  );

  const initialValues = {
    debug_mode: quiversValue.debug_mode ?? 0,
    stripe: quiversValue.stripe ?? 0,
    // api_access_token: quiversValue.api_access_token || '',
    // api_client_secret: quiversValue.api_client_secret || '',
    // api_client_id: quiversValue.api_client_id || '',
  };

  useEffect(() => {
    if (storeData?.length > 0) {
      setStore(storeData[0]);
      fetchQuivers(storeData[0]?.id);
    }
  }, [fetchQuivers, storeData]);

  return (
    <Box width={'70%'} pad={{ top: 'large' }} border={{ color: 'lightgray', size: 'xsmall', side: 'top' }}>
      <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues}>
        {({ values, handleSubmit, handleChange, isSubmitting, setFieldValue, touched, errors, handleBlur }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box pad={{ bottom: 'large' }}>
              <Text size="xlarge" margin={{ bottom: 'medium' }} weight="bold">
                Select the store for which you want to configure Quivers Pay and customize the settings as per your
                needs
              </Text>
              <Box width="48%">
                <Text margin={{ bottom: 'small' }} weight={'bold'}>
                  Select a store
                </Text>
                <FormField name="id" noBorder width={'80%'}>
                  <Box border="1px solid black">
                    <Select
                      options={storeData || []}
                      name="id"
                      onChange={e => {
                        if (e?.value) {
                          setStore(e?.value);
                          fetchQuivers(e?.value?.id);
                        } else {
                          setQuiversValue({});
                          setStore({});
                        }
                      }}
                      value={store?.id}
                      valueKey={{ key: 'id', reduce: false }}
                      labelKey="label"
                    />
                  </Box>
                </FormField>
              </Box>
            </Box>

            <Box margin={{ bottom: 'large' }}>
              <Text size="xlarge" margin={{ bottom: 'medium' }} weight="bold">
                Configuration Settings
              </Text>
              <Box
                justify="between"
                direction="row"
                margin={{ bottom: 'large' }}
                border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
              >
                <Box
                  width={'50%'}
                  margin={{ bottom: 'medium' }}
                  border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                >
                  <Box
                    direction="row"
                    gap="small"
                    // border={{ side: 'bottom', size: '1px' }}
                    margin={{ bottom: 'small' }}
                  >
                    <Text weight="bold">Stripe</Text>
                    <InfoText text="Hide and show Quivers stripe payment method on checkout." />
                  </Box>
                  <Box margin={{ bottom: 'small' }}>
                    <CheckBox
                      name="stripe"
                      toggle
                      reverse
                      disabled={!store?.id}
                      checked={values?.stripe}
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('stripe', 1);
                        } else {
                          setFieldValue('stripe', 0);
                          // setFieldValue('api_client_id', '');
                          // setFieldValue('api_client_secret', '');
                          // setFieldValue('api_access_token', '');
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box width={'46%'}>
                  <Box direction="row" gap="small" margin={{ bottom: 'small' }}>
                    <Text weight="bold">Debug</Text>
                    <InfoText text="Enable debug mode and click on save button to generate logs on Quivers end." />
                  </Box>
                  <Box margin={{ bottom: 'small' }}>
                    <CheckBox
                      name="debug_mode"
                      toggle
                      reverse
                      disabled={!store?.id}
                      checked={values?.debug_mode}
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('debug_mode', 1);
                        } else setFieldValue('debug_mode', 0);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {/* {values?.stripe ? (
                <Box>
                  <Text size="xlarge" margin={{ bottom: 'medium' }} weight="bold">
                    StoreLevel API Accounts
                  </Text>
                  <Box
                    justify="between"
                    direction="row"
                    margin={{ bottom: 'large' }}
                    border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
                  >
                    <Box
                      width="50%"
                      margin={{ bottom: 'medium' }}
                      border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                    >
                      <Box direction="row" gap="small">
                        <Text margin={{ bottom: 'small' }} weight="bold">
                          Client Id
                        </Text>
                      </Box>
                      <FormField name="api_client_id" noBorder error={touched.api_client_id && errors.api_client_id}>
                        <Box border="1px solid black" width="80%">
                          <TextInput
                            disabled={!values.stripe}
                            placeholder="Client Id"
                            pad="small"
                            name="api_client_id"
                            value={values?.api_client_id}
                            onChange={handleChange}
                          />
                        </Box>
                      </FormField>
                    </Box>

                    <Box width="46%">
                      <Box direction="row" gap="small">
                        <Text margin={{ bottom: 'small' }} weight="bold">
                          Client Secret
                        </Text>
                      </Box>
                      <FormField
                        name="api_client_secret"
                        noBorder
                        error={touched.api_client_secret && errors.api_client_secret}
                      >
                        <Box border="1px solid black" width="80%">
                          <TextInput
                            disabled={!values.stripe}
                            placeholder="Client Secret"
                            pad="small"
                            name="api_client_secret"
                            value={values?.api_client_secret}
                            onChange={handleChange}
                          />
                        </Box>
                      </FormField>
                    </Box>
                  </Box>
                  <Box
                    justify="between"
                    direction="row"
                    margin={{ bottom: 'large' }}
                    // border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
                  >
                    <Box
                      width="50%"
                      margin={{ bottom: 'medium' }}
                      // border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
                    >
                      <Box direction="row" gap="small">
                        <Text margin={{ bottom: 'small' }} weight="bold">
                          Access Token
                        </Text>
                      </Box>
                      <FormField
                        name="api_access_token"
                        noBorder
                        error={touched.api_access_token && errors.api_access_token}
                      >
                        <Box border="1px solid black" width="80%">
                          <TextInput
                            disabled={!values.stripe}
                            placeholder="Access Token"
                            pad="small"
                            name="api_access_token"
                            value={values?.api_access_token}
                            onChange={handleChange}
                          />
                        </Box>
                      </FormField>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <></>
              )} */}

              {/* <Box margin={{ top: 'large' }} alignSelf="end">
                <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} primary disabled={!store?.id} />
              </Box> */}
              {/* </Box> */}
              {/* </Box> */}
            </Box>
            <Box justify="end" gap="xlarge" direction="row" margin={{ vertical: 'large' }}>
              <Box align="end">
                <Button label={'Back'} primary onClick={() => setComp(comp - 1)} />
              </Box>
              <Box align="end">
                {_.isEqual(initialValues, values) ? (
                  <Button label={'Next'} primary onClick={() => setComp(comp + 1)} />
                ) : (
                  <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} primary disabled={!store?.id} />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

QuiversPay.propTypes = {
  setComp: PropTypes.func,
  errorToast: PropTypes.func,
  successToast: PropTypes.func,
  comp: PropTypes.number,
  data: PropTypes.object,
  initialValues: PropTypes.object,
  handleUpdateData: PropTypes.func,
  id: PropTypes.string,
  storeData: PropTypes.array,
};

export default QuiversPay;
