import React, { useEffect, useCallback } from 'react';
import { Box, Heading, Image } from 'grommet';

import { getCookie } from 'granite-admin/utils/storage';
import useQuery from 'granite-admin/utils/useQuery';

import quiversLogo from './assets/quivers.png';

const redirectUrl = process.env.REACT_APP_KONECT_REDIRECT_BASE_URL;

const Redirect = () => {
  const { query } = useQuery();

  const getRedirectUrl = useCallback(async () => {
    const id = getCookie('id');
    const business = getCookie('business');
    if (!id || !business) {
      window.open(
        `https://apollo.quivers${query.sandbox}.com/#/choose_business?destination=app_install/EcommBigcommerce?name=${query.name}%26redirect_domain=konectv2.quivers${query.sandbox}.com%26storehash=${query.unique_key}`,
        '_self',
      );
    } else {
      window.open(
        `${redirectUrl}bigcommerce/processing?id=${id}&business=${business}&unique_id=${query.unique_key}`,
        '_self',
      );
    }
  }, [query]);

  useEffect(() => {
    getRedirectUrl();
  }, [getRedirectUrl]);

  return (
    <Box
      fill
      align="center"
      justify="center"
      animation={{
        type: 'fadeIn',
        delay: 0,
        duration: 3000,
      }}
    >
      <Image src={quiversLogo} height="80px" width="80px" />
      <Heading level={4}>Mapping bigcommerce store to Quivers.</Heading>
    </Box>
  );
};

export default Redirect;
