// Lib Imports
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Heading, Text, Image } from 'grommet';

import useQuery from 'granite-admin/utils/useQuery';
import EventEmitter from 'granite-admin/utils/event-emitter';
import Loader from 'granite-admin/core/components/Loader';
import ErrorPage from 'konect-admin/components/ErrorPage';

import { validateStateToken } from 'sfdc/controllers/sfdc';
import clipArt from './assets/success.svg';

const Success = () => {
  const { query } = useQuery();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const [validation, setValidation] = useState('inProgress');

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'STATE_TOKEN_VALIDATION_SUCCESS':
          setValidation('pass');
          setTimeout(() => {
            window.opener.parent.postMessage({ message: 'AUTH_SUCCESS' }, '*');
            window.opener.redirecTo(
              '/sfdc/configs/configuration?business=' + event.data.businessRefId + '&id=' + event.data.id,
            );
            window.close();
          }, 1500);
          break;
        case 'STATE_TOKEN_VALIDATION_FAILURE':
          setValidation('fail');
          setTimeout(() => {
            window.parent.postMessage({ message: 'AUTH_FAILURE' }, '*');
            window.opener.redirecTo('/error');
            window.close();
          }, 1500);
          break;
        default:
          break;
      }
    });

    if (query.state) validateStateToken(eventEmitter, query.state, { code: query.code });
    return () => subscription.unsubscribe();
  }, [eventEmitter, query]);

  return (
    <Box flex background="white" width="100%" height="100%">
      {validation === 'inProgress' && (
        <Box fill align="center" justify="center">
          <Loader />
        </Box>
      )}
      {validation === 'pass' && (
        <Box fill align="center" justify="center" pad="large">
          <Heading level={1} size="medium">
            Connection Successful
          </Heading>
          <Text size="large">Returning back to Quivers Plugin Console</Text>
          <Text size="large">Don&apos;t press any key.</Text>
          <Image src={clipArt} fit="contain" />
        </Box>
      )}
      {validation === 'fail' && <ErrorPage />}
    </Box>
  );
};

Success.propTypes = {
  // add your proptypes here
};

export default Success;
