import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from 'grommet';

import { DataTable } from 'granite-admin/core/components/TableV3';
import useQuery from 'granite-admin/utils/useQuery';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import Loader from 'granite-admin/core/components/Loader';
import { COLUMNS } from 'middleware/constants/middleware';
import middlewareAPI from 'middleware/gateways/middleware-api';
import RowSubComponent from 'pages/middleware/components/RowSubComponent';

const ProfileCredentials = () => {
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(true);
  const { query } = useQuery();
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const eventEmitter = useMemo(() => new EventEmitter(), []);

  const getProfileData = useCallback(
    async params => {
      try {
        const data = await middlewareAPI.getProfileData(query.auth);
        setProfileData(data);
        setLoader(false);
      } catch (e) {
        console.log(e);
      }
    },
    [query],
  );

  const fetch = useCallback(
    async params => {
      let result = profileData?.list.sort((a, b) => {
        let fa = a?.clientName.toLowerCase(),
          fb = b?.clientName.toLowerCase();
        if (fa > fb) return 1;
        if (fa < fb) return -1;
      });
      if (params?.ordering && profileData?.list) {
        const list = profileData?.list.sort((a, b) => {
          if (params?.ordering === 'active_status' || params?.ordering === '-active_status') {
            let fa = JSON.parse(a.active_status),
              fb = JSON.parse(b.active_status);
            if (params?.ordering === 'active_status') return fa === fb ? 0 : fa ? -1 : 1;
            else return fa === fb ? 0 : fa ? 1 : -1;
          } else {
            let fa = a[params?.ordering.replace(/-/g, '')].toLowerCase(),
              fb = b[params?.ordering.replace(/-/g, '')].toLowerCase();
            if (params?.ordering.includes('-')) {
              return fa < fb ? 1 : -1;
            } else {
              return fa < fb ? -1 : 1;
            }
          }
        });
        result = list;
      }
      if (params?.search && profileData?.list) {
        const list = profileData?.list.filter(
          obj =>
            obj.clientName.toLowerCase().includes(params?.search.toLowerCase()) ||
            obj.ref_id.toLowerCase().includes(params?.search.toLowerCase()),
        );
        result = list;
      }
      return { list: result, count: result.length };
    },
    [profileData],
  );

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    const subscription = grandEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'UPDATE_MIDDLEWARE_TABLE':
          eventEmitter.emit('FETCH_LIST');
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [grandEmitter, eventEmitter]);

  return (
    <>
      {loader ? (
        <Box height="100px" align="center" justify="center">
          <Loader />
        </Box>
      ) : (
        <Box pad="medium" gap="small" overflow="auto">
          <DataTable
            columns={COLUMNS}
            fetch={fetch}
            selectable={false}
            withoutQuery={true}
            autoSavePreference={false}
            columnsHideable={false}
            expandable={true}
            showPagination={false}
            RowSubComponent={RowSubComponent}
            eventEmitter={eventEmitter}
          />
        </Box>
      )}
    </>
  );
};

export default ProfileCredentials;
