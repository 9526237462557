import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button } from 'grommet';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useQuery from 'granite-admin/utils/useQuery';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import Loader from 'granite-admin/core/components/Loader';
import { useToast } from 'granite-admin/core/components/Toast';
import JsonViewer from 'granite-admin/core/components/JsonViewer';

import middlewareAPI from 'middleware/gateways/middleware-api';

const LinkBox = styled(Box)`
   {
    & > :first-child > :first-child {
      height: 512px;
      overflow: auto;
    }
  }
`;

const RowSubComponent = ({ row, tableProps }) => {
  const { query } = useQuery();
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const [btnEnable, setBtnEnable] = useState(false);
  const [loader, setLoader] = useState(true);
  const [jsonData, setJsonData] = useState({});
  const { successToast, errorToast } = useToast();

  const updateProfileData = useCallback(async () => {
    try {
      await middlewareAPI.updateProfileData(jsonData.profile, query.auth);
      successToast('Profile data updated successfully!');
      grandEmitter.emit('UPDATE_MIDDLEWARE_TABLE');
    } catch (error) {
      errorToast('Profile data not updated!');
    }
  }, [successToast, errorToast, jsonData, query, grandEmitter]);

  useEffect(() => {
    setJsonData({
      profile: row.original.profile,
      profile_individual: row.original?.profileIndividual,
    });
    setLoader(false);
  }, [row]);

  return (
    <Box width="100%">
      {loader ? (
        <Box height="100px" align="center" justify="center">
          <Loader />
        </Box>
      ) : (
        <>
          {row.original.userAccess !== 'QuiversAdmin' ? (
            <LinkBox>
              <JsonViewer value={JSON.stringify(jsonData)} theme="rjv-default" />
            </LinkBox>
          ) : (
            <>
              <LinkBox>
                <JsonViewer
                  value={JSON.stringify(jsonData)}
                  theme="rjv-default"
                  onEdit={edit => {
                    if (edit.namespace[0] !== 'profile_individual') {
                      setJsonData(edit.updated_src);
                      setBtnEnable(true);
                    }
                  }}
                />
              </LinkBox>
              {btnEnable && (
                <Box width="120px" margin="small">
                  <Button
                    label="Update"
                    size="small"
                    primary
                    color={'brand'}
                    onClick={() => {
                      setLoader(true);
                      updateProfileData();
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

RowSubComponent.propTypes = {
  row: PropTypes.object,
  tableProps: PropTypes.any,
  eventEmitter: PropTypes.object,
};

export default RowSubComponent;
