const theme = {
  global: {
    colors: {
      'accent-1': '#162D6E',
      brand: '#2671B3',
      'brand-dark': '#7D5518',
      'brand-light': '#FDD598',
      border: '#C6C6C6',
      'comp-1': '#2D6E16',
      'comp-2': '#6E162D',
      'dark-1': '#222D39',
      'dark-2': '#707070',
      'dark-3': '#646464',
      hover: 'rgba(253,213,152,0.6)',
      warningBG: '#ff324d33',
      icon: '#646464',
      placeholder: '#AAAAAA',
      secondary: '#0E84AD',
      'secondary-light': '#95D7EE',
      'secondary-dark': '#15586F',
      'status-error': '#CC0000',
      'status-ok': '#24A000',
      'status-disabled': '#606060',
      'status-warning': '#D5CE00',
      info: '#08007E',
      text: { dark: 'white', light: '#222D39' },
      'sidebar-background': '#fff',
      'sidebar-group': '#606060',
      'sidebar-icon': '#2671B3',
      'sidebar-hover': '#012a46',
      'sidebar-divider': '#FDD598',
      'sidebar-svg-active': '#2671B3',
      'sidebar-svg-hover': '#2671B3',
      'sidebar-svg': '#2671B3',
      'sidebar-label-active': '#222D39',
      'sidebar-label': '#222D39',
      'sidebar-label-hover': '#222D39',
      'sidebar-label-active-background': 'rgba(253,213,152,0.6)',
    },
  },
  table: {
    body: {
      align: 'center',
      pad: { horizontal: 'large', vertical: 'xsmall' },
      background: {
        color: '#ffffff',
      },
    },
    header: {
      align: 'center',
      fill: 'horizontal',
      background: {
        color: '#2671B3',
      },
      round: true,
    },
  },
  toast: {
    position: 'top',
    margin: { top: '20px', horizontal: 'xxlarge' },
    colors: {
      success: 'status-ok',
      warning: 'status-warning',
      error: 'status-error',
      info: 'info',
    },
  },
  checkBox: {
    gap: 'medium',
    check: {
      radius: '2px',
    },
    border: {
      color: {
        dark: 'rgba(255, 255, 255, 0.5)',
        light: 'text',
      },
      width: '1px',
    },
    size: '16px',
    toggle: {
      knob: {
        extend: {
          top: '1px',
          left: '2px',
          width: '11px',
          height: '11px',
        },
      },
      size: '35px',
      color: { light: '#606060' },
      extend: ({ checked }) => {
        if (checked) return { borderColor: '#2671B3' };
      },
    },
  },
};
export default theme;
