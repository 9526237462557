import commonApi from 'common/gateways/common-api';

async function getData(eventEmitter, id, business) {
  try {
    const data = await commonApi.getData({ id, business });
    eventEmitter.emit('GET_DATA_SUCCESS', data);
  } catch (error) {
    eventEmitter.emit('GET_DATA_FAILURE');
  }
}

async function updateData(eventEmitter, business, payload) {
  try {
    await commonApi.updateData({ business, payload });
    eventEmitter.emit('UPDATE_DATA_SUCCESS');
  } catch (error) {
    eventEmitter.emit('UPDATE_DATA_FAILURE');
  }
}

export { getData, updateData };
