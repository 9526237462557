import GraniteError from 'granite-admin/utils/granite-error';
import graniteAPIClient from 'granite-admin/utils/granite-client';
import Warehouses from 'bigCommerce/entities/Warehouses';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

const dropdownUrl = process.env.REACT_APP_SHOPIFY_DROPDOWN;
const processUrl = process.env.REACT_APP_PROCESS_URL;
const quiversBaseUrl = process.env.REACT_APP_QUIVERS_BASE_URL;
const quiversMiddlewareUrl = process.env.REACT_APP_QUIVERS_MIDDLEWARE_URL;

async function postQuiversPay(params) {
  try {
    const { data } = await graniteAPIClient(processUrl).post('/api/updateSettings', params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function saveBopisSettings(params) {
  try {
    const { data } = await graniteAPIClient(processUrl).post('/api/save-plugin-data', params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

// async function deactivateBopis(params) {
//   try {
//     const { data } = await graniteAPIClient(processUrl).post('/api/bopis_deactivate', params);
//     return data;
//   } catch (e) {
//     throw new GraniteError(e.errors);
//   }
// }

async function getBigCommerceData(params) {
  try {
    const { data } = await graniteAPIClient(processUrl).post('/api/plugin_data', params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getQuiversPay(params) {
  try {
    const { data } = await graniteAPIClient(processUrl).get(`/api/get-settings/${params}`);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getWarehouses(business) {
  try {
    const { data } = await graniteAPIClient(dropdownUrl, {
      Authorization: getAuthToken(),
    }).get(`/Warehouses/Search?refId=${business}`);
    const list = data?.map(item => new Warehouses(item));
    return list;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getDashboardData(params) {
  try {
    const { data } = await graniteAPIClient(quiversMiddlewareUrl, {
      Authorization: 'apikey c577bec9-07e6-4b4f-920d-b505409a620a',
    }).get(`/statics`, {
      params: params,
    });
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function updateBopisTimer(business, payload) {
  try {
    const { data } = await graniteAPIClient(quiversBaseUrl, {
      Authorization: getAuthToken(),
    }).post(`/updateTimers?business=${business}`, payload);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

export default {
  getWarehouses,
  postQuiversPay,
  getBigCommerceData,
  getQuiversPay,
  saveBopisSettings,
  updateBopisTimer,
  getDashboardData,
};
