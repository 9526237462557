import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import InfoText from 'common/components/infoText';
import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';

const StyledBox = styled(Box)`
  width: -webkit-fill-available;
  border: 1px solid #c6c6c6;
`;

const OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const INTERVAL = [
  { label: 'Every 5 min', value: '5' },
  { label: 'Every 10 min', value: '10' },
  { label: 'Every 30 min', value: '30' },
  { label: 'Every 1 hour', value: '60' },
];

const INTERVALS = [
  { label: 'Every 30 min', value: '30' },
  { label: 'Every 1 hour', value: '60' },
];

const flowSettingsData = [
  {
    name: 'sync_quivers_retailer_shipments',
    label: 'Sync retailer shipments from Quivers to Bigcommerce',
    time: "Don't show",
  },
  { name: 'sync_quivers_shipments', label: 'Sync shipments from Quivers to Bigcommerce', time: "Don't show" },
  {
    name: 'sync_quivers_retailer_returns',
    label: 'Sync retailer returns from Quivers to Bigcommerce',
    time: "Don't show",
  },
  { name: 'sync_quivers_cancellations', label: 'Sync cancellation from Quivers to Bigcommerce', time: "Don't show" },
  { name: 'sync_quivers_returns', label: 'Sync refund from Quivers to Bigcommerce', time: "Don't show" },
  {
    name: 'sync_quivers_inventory',
    label: 'Sync inventory From Quivers to Bigcommerce',
    timeInfo: 'Inventory will be synced from Quivers to BigCommerce periodically based on the selected time.',
  },
  {
    name: 'sync_client_refunds',
    label: 'Sync refund From Bigcommerce to Quivers',
    timeInfo: 'Refunds created on BigCommerce will be synced periodically onto Quivers based on the selected time',
  },
  {
    name: 'sync_client_orders',
    label: 'Sync orders From Bigcommerce to Quivers',
    timeInfo: 'Orders will be synced from BigCommerce to Quivers periodically based on the selected time.',
  },
  {
    name: 'sync_client_cancellations',
    label: 'Sync cancellation From Bigcommerce to Quivers',
    timeInfo: 'Cancellations will be synced from BigCommerce to Quivers periodically based on the selected time.',
  },
  {
    name: 'sync_client_shipments',
    label: 'Sync shipment From Bigcommerce to Quivers',
    timeInfo: 'Shipments will be synced from BigCommerce to Quivers periodically based on the selected time.',
  },
  {
    name: 'claim_orders',
    label: 'Claim Orders',
    timeInfo: 'Merchant available orders will be auto claimed periodically based on the selected time',
  },
  {
    name: 'sync_products',
    label: 'Sync products From Bigcommerce to Quivers',
    timeInfo: 'Products will be synced from BigCommerce to Quivers periodically based on the selected time.',
  },
];

const FlowSettings = ({ setComp, comp, initialValues, handleUpdateData, data, id, errorToast }) => {
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      const payload = {
        id,
        settings: {
          ...data?.settings?.dynamic,
          configuration: {
            ...data?.settings?.dynamic?.configuration,
            ...values,
          },
        },
        displayName: data?.displayName,
      };
      await handleUpdateData(payload);
      // window.parent.postMessage({ message: 'AUTH_SUCCESS' }, '*');
      setComp(comp + 1);
    } catch (e) {
      errorToast(e.errors?.title || 'Failed to Update Data');
    }
    setSubmitting(false);
  };

  return (
    <Box width="70%">
      <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues}>
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Text size="xlarge" weight="bold">
              Default settings are already enabled and can be used as it is. In case of modifications, make the required
              changes and save.
            </Text>
            <Box margin={{ top: 'medium' }}>
              {flowSettingsData.map((i, index) => (
                <Box key={index}>
                  <Box flex direction="row" gap="medium" justify="between" margin={{ bottom: 'small' }}>
                    <Box width={i?.time ? '100%' : '48%'}>
                      <Text size="medium">{i.label}</Text>
                    </Box>
                  </Box>
                  <Box direction="row" gap="medium" justify="between" key={i.name}>
                    <FormField name={`${i.name}.flag`} width={i?.time ? '100%' : '48%'} noBorder>
                      <Box border="1px solid black">
                        <Select
                          options={OPTIONS}
                          name={`${i.name}.flag`}
                          onChange={e => {
                            if (e?.value) {
                              setFieldValue(`${i.name}.flag`, e.value);
                              if (values[i?.name]?.flag) {
                                setFieldValue(`${i.name}.interval`, '');
                              }
                            } else {
                              setFieldValue(`${i.name}.flag`, false);
                            }
                          }}
                          value={values[i?.name]?.flag}
                          valueKey={{ key: 'value', reduce: true }}
                          labelKey="label"
                        />
                      </Box>
                    </FormField>
                    {!i?.time && (
                      <>
                        {i?.name === 'sync_products' ? (
                          <FormField name={`${i.name}.interval`} width="48%" noBorder>
                            <Box direction="row">
                              <StyledBox>
                                <Select
                                  options={INTERVALS}
                                  name={`${i.name}.interval`}
                                  onChange={e => setFieldValue(`${i.name}.interval`, e.value)}
                                  value={values[i?.name]?.interval}
                                  valueKey={{ key: 'value', reduce: true }}
                                  labelKey="label"
                                  disabled={!values[i?.name]?.flag}
                                />
                              </StyledBox>
                              <InfoText text={i.timeInfo} />
                            </Box>
                          </FormField>
                        ) : (
                          <FormField name={`${i.name}.interval`} width="48%" noBorder>
                            <Box direction="row">
                              <StyledBox>
                                <Select
                                  options={INTERVAL}
                                  name={`${i.name}.interval`}
                                  onChange={e => setFieldValue(`${i.name}.interval`, e.value)}
                                  value={values[i?.name]?.interval}
                                  valueKey={{ key: 'value', reduce: true }}
                                  labelKey="label"
                                  disabled={!values[i?.name]?.flag}
                                />
                              </StyledBox>
                              <InfoText text={i.timeInfo} />
                            </Box>
                          </FormField>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box justify="end" gap="xlarge" direction="row" margin={{ vertical: 'medium' }}>
              <Box align="end">
                <Button label={'Back'} primary onClick={() => setComp(comp - 1)} disabled={isSubmitting} />
              </Box>
              <Box align="end">
                {_.isEqual(initialValues, values) ? (
                  <Button label={'Next'} primary onClick={() => setComp(comp + 1)} disabled={isSubmitting} />
                ) : (
                  <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} primary disabled={isSubmitting} />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

FlowSettings.propTypes = {
  setComp: PropTypes.func,
  comp: PropTypes.number,
  initialValues: PropTypes.object,
  handleUpdateData: PropTypes.func,
  errorToast: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.string,
  fetchData: PropTypes.func,
  setDisabledAll: PropTypes.func,
  business: PropTypes.string,
};

export default FlowSettings;
