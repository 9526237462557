import React, { useState } from 'react';
import { Box, Text, Button } from 'grommet';
// import { AddCircle } from 'grommet-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ConfirmPopup from 'granite-admin/core/components/Confirm';
import InfoText from 'common/components/infoText';

import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';
import { setCookie } from 'granite-admin/utils/storage';

import Tooltip from 'granite-admin/core/components/Tooltip';

const StyledLayer = styled(ConfirmPopup)`
background-color: rgba(0,0,0,0.5);

> div {background-color:white;

  border: 1px black solid;}
@media (max-width: 768px) {
  > div {         
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;}
  
  
`;

const konectBaseUrl = process.env.REACT_APP_BIGCOMMERCE_ACTIVATE;

const MarketPlaces = ({ setComp, comp, InitialValuesStore, business, id }) => {
  const [layer, setLayer] = useState(false);

  const [datas, setDatas] = useState(InitialValuesStore?.[0]);

  return (
    <Box width={'70%'} pad={{ top: 'large' }} border={{ color: 'lightgray', size: 'xsmall', side: 'top' }}>
      <Box pad={{ bottom: 'large' }}>
        <Text size="xlarge" margin={{ bottom: 'medium' }} weight="bold">
          Add the Store for which you want to configure Quivers Pay settings. The orders from BigCommerce will be pushed
          on to the corresponding Business on Quivers.
        </Text>
        <Box direction="row">
          <Box width="40%">
            <Text margin={{ bottom: 'small' }} width="80%" weight={'bold'}>
              Select a Store
            </Text>
            <FormField name="id" noBorder width={'95%'}>
              <Box border="1px solid black">
                <Select
                  options={InitialValuesStore || []}
                  name="id"
                  onChange={e => {
                    if (e.value) {
                      setLayer(e?.value);
                    } else setDatas({});

                    // setDatas(e?.value);
                  }}
                  value={datas?.id}
                  valueKey={{ key: 'id', reduce: false }}
                  labelKey="label"
                />
              </Box>
            </FormField>
          </Box>
          <Box direction="row" alignSelf="end" margin={{ bottom: 'small', right: 'xsmall' }}>
            <Button
              style={{ padding: '10px 2rem' }}
              label={
                <Tooltip content="you can add multiple store from here">
                  <Text>Add store</Text>
                </Tooltip>
              }
              // icon={<AddCircle color="brand" size="17px" />}
              onClick={() => {
                setCookie('id', id);
                setCookie('business', business);

                const child = window.open(konectBaseUrl, '', 'width=800,height=600');
                const checkChild = () => {
                  if (child.closed) {
                    clearInterval(timer);
                  }
                };
                var timer = setInterval(checkChild, 500);
              }}
              primary
            />
          </Box>
        </Box>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Box
          justify="between"
          direction="row"
          margin={{ bottom: 'large' }}
          border={{ color: 'lightgray', size: 'xsmall', side: 'bottom' }}
        >
          <Box
            width={'50%'}
            margin={{ bottom: 'medium' }}
            border={{ color: 'lightgray', size: 'xsmall', side: 'right' }}
          >
            <Text margin={{ bottom: 'small' }} weight={'bold'}>
              Store Name
            </Text>
            <Box border="1px solid black" background={'silver'} pad={'small'} width={'50%'}>
              <Text truncate="tip">{datas?.store_name}</Text>
            </Box>
          </Box>
          <Box width={'40%'}>
            <Text margin={{ bottom: 'small' }} weight={'bold'}>
              Store Hash
            </Text>
            <Box border="1px solid black" background={'silver'} pad={'small'} width={'58%'}>
              <Text truncate="tip">{datas?.id}</Text>
            </Box>
          </Box>
        </Box>
        <Box width={'45%'}>
          <Text margin={{ bottom: 'small' }} weight={'bold'}>
            Store URL
          </Text>
          <Box border="1px solid black" background={'silver'} pad={'small'}>
            <Text truncate="tip">{datas?.store_url}</Text>
          </Box>
        </Box>
      </Box>

      {layer && (
        <Box>
          <StyledLayer
            width="25rem"
            isVisible
            heading="Changing Store"
            onOK={() => {
              setDatas(layer);
              setLayer(false);
            }}
            text="You are changing the store therefore some fields might change accordingly. Are you sure you wish to continue?"
            togglePopup={() => {
              setLayer(false);
            }}
            labelCancel="Cancel"
            labelOK="Confirm"
          />
        </Box>
      )}

      <Box justify="end" gap="xlarge" direction="row" margin={{ top: 'large' }}>
        <Box align="end">
          <Button label={'Next'} primary onClick={() => setComp(comp + 1)} />
        </Box>
      </Box>
    </Box>
  );
};

MarketPlaces.propTypes = {
  setComp: PropTypes.func,
  comp: PropTypes.number,
  initialValues: PropTypes.object,
  handleUpdateData: PropTypes.func,
  business: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  errorToast: PropTypes.func,
  setDisabledAll: PropTypes.func,
  setData: PropTypes.func,
  InitialValuesStore: PropTypes.any,
};

export default MarketPlaces;
