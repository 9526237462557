import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import MiddlewareEntity from 'middleware/entities/middlewaretable';

/* eslint-disable */
const middlewareUrl = process.env.REACT_APP_MIDDLEWARE_BASE_URL;

async function getProfileData(auth) {
  try {
    const { data } = await GraniteAPIClient(middlewareUrl, {
      Authorization: `${auth}`,
    }).get(`getProfileList`);
    let list = data?.profiles.map(item => new MiddlewareEntity(item));
    return { list, count: list.length };
  } catch (error) {
    console.log(error);
  }
}

async function updateProfileData(params, auth) {
  try {
    const payload = { ...params };
    const { data } = await GraniteAPIClient(middlewareUrl, {
      Authorization: `${auth}`,
    }).post(`updateProfile`, payload);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export default {
  getProfileData,
  updateProfileData,
};
