import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import MarketPlaceEntity from 'common/entities/Marketplace';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

/* eslint-disable */
const quiversUrl = process.env.REACT_APP_QUIVERS_BASE_URL;
const dropdownUrl = process.env.REACT_APP_SHOPIFY_DROPDOWN;

async function getData(params) {
  try {
    const { data } = await GraniteAPIClient(quiversUrl, {
      Authorization: getAuthToken(),
    }).get(`GetBusinessPluginById`, { params: params });
    return data.result;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function updateData({ business, payload }) {
  try {
    const { data } = await GraniteAPIClient(quiversUrl, {
      Authorization: getAuthToken(),
    }).post(`Update?business=${business}`, payload);
    return data.result;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function ActivatePlugin({ business }, plugin_id) {
  try {
    let payload = {
      id: plugin_id,
    };
    const { data } = await GraniteAPIClient(quiversUrl, {
      Authorization: getAuthToken(),
    }).post(`Activate?business=${business}`, payload);
    return data.result;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getMarketplace(business) {
  try {
    const { data } = await GraniteAPIClient(dropdownUrl, {
      Authorization: getAuthToken(),
    }).get(`ProductGroups/MyProductGroups?refId=${business}`);
    let list = data.filter(i => {
      if (i?.Type === 'Marketplace') return i;
    });
    list = list?.map(item => new MarketPlaceEntity(item));
    return list;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

export default {
  getData,
  updateData,
  getMarketplace,
  ActivatePlugin,
};
