import sfdcApi from 'sfdc/gateways/sfdc-api';

async function getAuthObj(eventEmitter, params) {
  try {
    const data = await sfdcApi.getAuthObj(params);
    eventEmitter.emit('CALL_SUCCESS', data);
  } catch (error) {
    eventEmitter.emit('CALL_FAILURE');
  }
}

async function getSalesforceUrl(eventEmitter, params) {
  try {
    const url = await sfdcApi.getURL(params);
    eventEmitter.emit('GET_SF_URL_SUCCESS', url);
  } catch (error) {
    eventEmitter.emit('GET_SF_URL_FAILURE');
  }
}

async function validateStateToken(eventEmitter, business, payload) {
  try {
    const data = await sfdcApi.register(business, payload);
    eventEmitter.emit('STATE_TOKEN_VALIDATION_SUCCESS', data);
  } catch (error) {
    eventEmitter.emit('STATE_TOKEN_VALIDATION_FAILURE');
  }
}

async function getDashboardData(eventEmitter, params) {
  try {
    const data = await sfdcApi.getDashboardData(params);
    eventEmitter.emit('GET_DASHBOARD_DATA_SUCCESS', data);
  } catch (error) {
    eventEmitter.emit('GET_DASHBOARD_DATA_FAILURE');
  }
}

async function updateConfig(eventEmitter, business, params,test) {
  try {
    const data = await sfdcApi.updateConfig(business, params);
    eventEmitter.emit('CONFIG_UPDATE_SUCCESS', {
      data,
      test
    });
  } catch (error) {
    eventEmitter.emit('CONFIG_UPDATE_FAILURE');
  }
}

export { getAuthObj, getSalesforceUrl, validateStateToken, getDashboardData, updateConfig };
