class MiddlewareTable {
  constructor(data) {
    this.clientName = data?.profile?.client_name;
    this.platform = data?.profile.client_type;
    this.ref_id = data?.profile?.ref_id;
    this.active_status = data?.profile?.is_active;
    this.order_sync_status =
      data?.profile?.client_type === 'Shopify' && data?.profile?.is_active === 'true'
        ? true
        : data?.profile_individual?.orders_sync_status ?? 'NA';
    this.profileIndividual = {
      api_error: data?.profile_individual?.error_message ?? 'NA',
      order_sync_status:
        data?.profile?.client_type === 'Shopify' && data?.profile?.is_active === 'true'
          ? true
          : data?.profile_individual?.orders_sync_status ?? 'NA',
      last_order_fetch_time: data?.profile_individual?.handler_timer ?? 'NA',
    };
    this.userAccess = data?.user_access;
    this.profile = data?.profile ?? [];
  }
}

export default MiddlewareTable;
