import React from 'react';
import { Box, Stack, Diagram, Text } from 'grommet';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinkBox = styled(Box)`
   {
    border-radius: 75%;
    behavior: url(PIE.htc); /* remove if you don't care about IE8 */
    height: 20px;
    width: 20px;
  }
`;

const LinkBox2 = styled(Box)`
   {
    border-radius: 75%;
    behavior: url(PIE.htc); /* remove if you don't care about IE8 */
    height: 10px;
    width: 10px;
  }
`;

const disableStyle = {
  pointerEvents: 'none',
  opacity: '0.5',
};

const Connectors = ({ activeId, steps, setComp, disableIdx, disabledAllIndx }) => {
  const toTarget = steps.length;
  return (
    <Box flex={false} width="xxlarge">
      <Stack guidingChild={1}>
        <Diagram
          connections={[
            {
              fromTarget: '1',
              toTarget: toTarget.toString(),
              thickness: 'xsmall',
              offset: 'large',
              color: 'black',
            },
          ]}
        />
        <Box direction="row" justify="center" gap="xlarge">
          {steps.map((item, index) => (
            <Box key={index}>
              <LinkBox
                margin={{ horizontal: 'medium' }}
                pad="large"
                background={'white'}
                justify="center"
                align="center"
                alignSelf="start"
              >
                <LinkBox2
                  id={index + 1}
                  margin={{ horizontal: 'medium' }}
                  pad="medium"
                  elevation="small"
                  background={index === activeId ? 'brand' : 'white'}
                  justify="center"
                  align="center"
                  alignSelf="center"
                  onClick={() => setComp(index + 1)}
                  style={index == disableIdx || disabledAllIndx ? disableStyle : null}
                >
                  <Text weight="bold" size="large">
                    {index + 1}
                  </Text>
                </LinkBox2>
                <Box align="center">
                  <Text margin={{ top: 'small' }} style={{ width: '100px', textAlign: 'center' }}>
                    {item ? item : null}
                  </Text>
                </Box>
              </LinkBox>
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

Connectors.defaultProps = {
  disableIdx: -1,
};

Connectors.propTypes = {
  activeId: PropTypes.number,
  steps: PropTypes.array,
  setComp: PropTypes.func,
  disableIdx: PropTypes.number,
  disabledAllIndx: PropTypes.bool,
};

export default Connectors;
